import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isFunction from 'lodash/isFunction'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import mingo from 'mingo'

import { ReactComponent as WarningIcon } from '../../../../../common/assets/images/warning-icon.svg'
import Button from '../../../../../common/components/atoms/Button'
import Heading from '../../../../../common/components/atoms/Heading'
import Modal from '../../../../../common/components/atoms/Modal'
import Text from '../../../../../common/components/atoms/Text'
import useInjectDynamic from '../../../../../common/hooks/useInjectDynamic'
import amplitude from '../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../../Dashboard/utils/hasCoreProduct'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'

const InterceptModal = ({
  show,
  onClose,
  payments,
  questionFragments = {},
  interceptModalData,
  dispatchToQuestion,
  dispatchNextQuestion,
  dispatchPreviousQuestion,
}) => {
  const { resolveValue } = useInjectDynamic({ questionFragments })

  const {
    label,
    label2,
    subLabel,
    primaryButtonIcon,
    primaryButtonLabel,
    primaryButtonAction,
    secondaryButtonIcon,
    secondaryButtonLabel,
    secondaryButtonAction,
    secondaryButtonTrigger,
  } = interceptModalData

  useEffect(() => {
    if (show) {
      amplitude.sendEvent('TriggeredInterceptModal', {
        label,
        subLabel,
        primaryButtonLabel,
        secondaryButtonLabel,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const handleButtonAction = (actionObj) => {
    switch (actionObj.type) {
      case CONSTANTS.interceptActionTypes.NEXT_QUESTION:
        amplitude.sendEvent('ClickedInterceptModalButton', {
          state: 'Next Question',
          withFragments: !!actionObj.questionFragments,
        })

        return dispatchNextQuestion({
          ...questionFragments,
          ...actionObj.questionFragments,
        })

      case CONSTANTS.interceptActionTypes.PREVIOUS_QUESTION:
        amplitude.sendEvent('ClickedInterceptModalButton', {
          state: 'Previous Question',
        })

        return dispatchPreviousQuestion()

      case CONSTANTS.interceptActionTypes.TO_QUESTION:
        amplitude.sendEvent('ClickedInterceptModalButton', {
          state: 'To Question',
          questionId: actionObj.questionId,
        })

        return dispatchToQuestion({
          questionId: _isFunction(actionObj.questionId)
            ? resolveValue(actionObj.questionId)
            : actionObj.questionId,
        })

      default:
        return onClose()
    }
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const buttonTestObj = {
    meta: { hasCoreProductPayment: hasCoreProduct(payments) },
  }

  const showSecondaryButton =
    secondaryButtonLabel &&
    secondaryButtonAction &&
    (!secondaryButtonTrigger ||
      new mingo.Query(secondaryButtonTrigger).test(buttonTestObj))

  return (
    <Modal maxWidth="sm" show={show} onClose={onClose}>
      <Box
        p={isMobile ? '0' : '0.8rem 0'}
        display="flex"
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        data-testid="intercept-modal"
      >
        <WarningIcon />

        <Box pt="1.4rem">
          <Heading sx={{ lineHeight: '1.24' }} variant="h3">
            {resolveValue(label)}
          </Heading>
        </Box>

        {label2 && (
          <Heading sx={{ mt: '1.3rem', lineHeight: '1.24' }} variant="h3">
            {resolveValue(label2)}
          </Heading>
        )}

        {subLabel && (
          <Box pt="0.9rem">
            <Text
              sx={{
                '& a': {
                  color:
                    themeColors[themeColorTypes.ACCENT_1][
                      themeColorVariants.MAIN
                    ],
                  '&:hover': { textDecoration: 'underline' },
                },
              }}
              size="md"
              dangerouslySetInnerHTML={{ __html: resolveValue(subLabel) }}
            />
          </Box>
        )}

        <Box pt="2.5rem" pb="0.5rem">
          <Button
            size="md"
            variant="contained"
            data-testid="intercept-modal-primary-button"
            onClick={() => handleButtonAction(primaryButtonAction)}
            endIcon={primaryButtonIcon ? <ArrowForwardIcon /> : undefined}
            label={resolveValue(primaryButtonLabel)}
          />
        </Box>

        {showSecondaryButton && (
          <Button
            size="xs"
            iconFontSize="1rem"
            color={themeColorTypes.GREY}
            data-testid="intercept-modal-secondary-button"
            onClick={() => handleButtonAction(secondaryButtonAction)}
            endIcon={secondaryButtonIcon ? <ArrowForwardIcon /> : undefined}
            label={resolveValue(secondaryButtonLabel)}
          />
        )}
      </Box>
    </Modal>
  )
}

InterceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  interceptModalData: PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]).isRequired,
    label2: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    subLabel: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    primaryButtonIcon: PropTypes.bool,
    primaryButtonLabel: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]).isRequired,
    primaryButtonAction: PropTypes.shape({
      type: PropTypes.string,
      questionId: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    }),
    secondaryButtonIcon: PropTypes.bool,
    secondaryButtonLabel: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    secondaryButtonAction: PropTypes.shape({
      type: PropTypes.string,
      questionId: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    }),
    secondaryButtonTrigger: PropTypes.shape({}),
  }).isRequired,
  questionFragments: PropTypes.shape({}),
  dispatchNextQuestion: PropTypes.func.isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  interceptModalData: navigationSelectors.getInterceptModal(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: (questionFragments) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
  dispatchToQuestion: ({ questionId }) =>
    dispatch(
      navigationActions.toQuestion({ questionId, fromDashboard: false }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(InterceptModal)
