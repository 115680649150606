import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector)

const fallbackLng = CONSTANTS.languageTypes.EN_CA.id
const supportedLngs = Object.values(CONSTANTS.languageTypes).map(({ id }) => id)

// Base config
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng,
    supportedLngs,
    interpolation: {
      // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
    ns: [
      'appointees',
      'bonusDocs',
      'common',
      'components',
      'corporateExecutors',
      'customQuestion',
      'dashboard',
      'documents',
      'interceptModals',
      'molecules',
      'navigation',
      'pages',
      'payment',
      'provinceDictionary',
      'questionnaire.basics',
      'questionnaire.charities',
      'questionnaire.complete',
      'questionnaire.distribution',
      'questionnaire.family',
      'questionnaire.guardians',
      'questionnaire.misc',
      'questionnaire.personalPoa',
      'questionnaire.propertyPoa',
      'questionnaire.social',
      'questionnaire.socialComplete',
      'questionnaire.trustee',
      'sidebar',
      'summary',
      'validation',
    ],
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
  })

export default i18n
