import _upperFirst from 'lodash/upperFirst'

import { CONSTANTS } from '@epilogue/common'

import {
  ONTARIO_TERMS,
  provinceDictionary,
} from '../../../../common/utils/provinceDictionary'
import appointeeTypes from '../appointeeTypes'
import personalPoaDnrSidebar from '../sidebarContent/personalPoaDnrSidebar'
import tKeyRelationshipOptions from '../utils/tKeyRelationshipOptions'

// TAG: province-specific
const subLabelNoHealthcareWorkers = ({ answerStore }) => {
  const { province } = answerStore

  const PROVINCES_THAT_ALLOW_HEALTHCARE_WORKERS_CARE_POA = [
    CONSTANTS.provinces.ALBERTA,
    CONSTANTS.provinces.MANITOBA,
    CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR,
    CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
    CONSTANTS.provinces.SASKATCHEWAN,
  ]

  // show nothing if the province allows healthcare workers as Care POA
  if (PROVINCES_THAT_ALLOW_HEALTHCARE_WORKERS_CARE_POA.includes(province)) {
    return undefined
  }

  // BC requires specific copy for this label
  if (province === CONSTANTS.provinces.BRITISH_COLUMBIA) {
    return {
      tKey: 'questionnaire.personalPoa:common.subLabelNoHealthcareWorkers.bc',
    }
  }

  // all other provinces will see this copy
  return {
    tKey: 'questionnaire.personalPoa:common.subLabelNoHealthcareWorkers.default',
  }
}

const rbcForbiddenTerms = [
  {
    allow: false,
    terms: ['rbc'],
    errMsg: { tKey: 'common:rbcForbiddenTermMessage' },
  },
  {
    allow: false,
    terms: ['royal trust', 'royal bank'],
    errMsg:
      'RBC Royal Trust cannot accept this type of appointment. You must appoint an individual in this role.',
  },
  {
    allow: false,
    terms: ['banque royale', 'trust royal'],
    errMsg:
      'RBC Trust Royal ne peut pas accepter ce type de nomination. Vous devez nommer un particulier à ce poste.',
  },
]

const section = CONSTANTS.questionnaireSectionTypes.PERSONAL_POA.id

export default {
  id: section,
  trigger: {
    product: 'willPoa',
    // TAG: quebec
    province: { $ne: CONSTANTS.provinces.QUEBEC },
  },
  description: { tKey: 'questionnaire.personalPoa:description' },
  questions: [
    {
      id: 1572444356,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.personalPoa:intro.highlightPillText',
      },

      label: ({ answerStore }) => {
        const { province } = answerStore
        const ontarioTerm = ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PERSONAL_CARE
        return provinceDictionary(ontarioTerm, province)
      },

      subLabel: ({ answerStore }) => {
        const { province } = answerStore
        const ontarioTerm = ONTARIO_TERMS.ATTORNEY

        if (
          [
            CONSTANTS.provinces.SASKATCHEWAN,
            CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
          ].includes(province)
        ) {
          return {
            tKey: 'questionnaire.personalPoa:intro.subLabel.peiSaskatchewan',
            options: { term: provinceDictionary(ontarioTerm, province) },
          }
        }

        if (province === CONSTANTS.provinces.ONTARIO) {
          return {
            tKey: 'questionnaire.personalPoa:intro.subLabel.ontario',
          }
        }

        return {
          tKey: 'questionnaire.personalPoa:intro.subLabel.default',
          options: { term: provinceDictionary(ontarioTerm, province) },
        }
      },
    },

    {
      id: 1572444357,
      pathname: 'partner-personal-poa',
      section,

      label: ({
        answerStore: { province, partnerFirstName, partnerLastName },
      }) => ({
        tKey: `questionnaire.personalPoa:partner-personal-poa.label.${province === CONSTANTS.provinces.SASKATCHEWAN ? 'saskatchewan' : 'default'}`,
        options: { partnerFirstName, partnerLastName },
      }),

      subLabel: ({ answerStore }) => {
        const { province, partnerFirstName } = answerStore
        const ontarioTerm = ONTARIO_TERMS.ATTORNEY

        const hideSubLabelProvinces = [
          CONSTANTS.provinces.MANITOBA,
          CONSTANTS.provinces.NOVA_SCOTIA,
          CONSTANTS.provinces.SASKATCHEWAN,
        ]

        /*
         * If province is Manitoba, Nova Scotia, or Saskatchewan, return + display
         * nothing, everything else returns the string
         */
        return hideSubLabelProvinces.includes(province)
          ? undefined
          : {
              tKey: 'questionnaire.personalPoa:partner-personal-poa.subLabel',
              options: {
                partnerFirstName,
                term: provinceDictionary(ontarioTerm, province),
              },
            }
      },

      fields: [
        {
          type: 'quickRadio',
          name: 'partnerAsPersonalPoa',
          reviewLabel: ({ answerStore }) => {
            const { province, partnerFirstName, partnerLastName } = answerStore
            return {
              tKey: 'questionnaire.personalPoa:partner-personal-poa.fields.partnerAsPersonalPoa.reviewLabel',
              options: {
                partnerFirstName,
                partnerLastName,
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE,
                  province,
                ),
              },
            }
          },
          appointeeType: appointeeTypes.PARTNER_PERSONAL_POA,
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572444358,
      pathname: 'partner-co-personal-poa',
      section,
      optional: true,

      label: ({ answerStore: { partnerFirstName, partnerLastName } }) => ({
        tKey: 'questionnaire.personalPoa:partner-co-personal-poa.label',
        options: { partnerFirstName, partnerLastName },
      }),

      label2: {
        tKey: 'questionnaire.personalPoa:partner-co-personal-poa.label2',
      },

      subLabel: subLabelNoHealthcareWorkers,

      fields: [
        {
          name: 'partnerCoPersonalPoaName',
          type: 'text',
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            return {
              tKey: 'questionnaire.personalPoa:partner-co-personal-poa.fields.partnerCoPersonalPoaName.reviewLabel',
              options: {
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE,
                  province,
                ),
              },
            }
          },
          appointeeType: appointeeTypes.CO_PERSONAL_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'partnerCoPersonalPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: { $exists: true } },
          { province: { $ne: CONSTANTS.provinces.MANITOBA } },
          { province: { $ne: CONSTANTS.provinces.NOVA_SCOTIA } },
          { province: { $ne: CONSTANTS.provinces.SASKATCHEWAN } },
          { partnerAsPersonalPoa: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572444561,
      pathname: 'partner-alt-personal-poa',
      section,
      optional: true,

      label: ({ answerStore }) => {
        const {
          province,
          partnerFirstName,
          partnerLastName,
          partnerCoPersonalPoaName,
        } = answerStore
        const ontarioTerm = ONTARIO_TERMS.ATTORNEY

        return {
          tKey: `questionnaire.personalPoa:partner-alt-personal-poa.label.case${partnerCoPersonalPoaName ? '1' : '2'}`,
          options: {
            term: provinceDictionary(ontarioTerm, province),
            partnerFirstName,
            partnerLastName,
            partnerCoPersonalPoaName,
          },
        }
      },

      subLabel: subLabelNoHealthcareWorkers,

      fields: [
        {
          name: 'altPartnerPersonalPoaName',
          type: 'text',
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            const ontarioTerm = ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE
            return {
              tKey: 'questionnaire.personalPoa:partner-alt-personal-poa.fields.altPartnerPersonalPoaName.reviewLabel',
              options: { term: provinceDictionary(ontarioTerm, province) },
            }
          },
          appointeeType: appointeeTypes.ALTERNATE_PERSONAL_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'altPartnerPersonalPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { partnerAsPersonalPoa: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572444754,
      pathname: 'non-partner-personal-poa',
      section,
      label: ({ answerStore: { province } }) => ({
        tKey: `questionnaire.personalPoa:non-partner-personal-poa.label.${province === CONSTANTS.provinces.SASKATCHEWAN ? 'saskatchewan' : 'default'}`,
      }),

      subLabel: subLabelNoHealthcareWorkers,

      fields: [
        {
          name: 'nonPartnerPersonalPoaName',
          type: 'text',
          optional: false,
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore: { province } }) =>
            _upperFirst(
              provinceDictionary(
                ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE,
                province,
              ),
            ),
          appointeeType: appointeeTypes.PERSONAL_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'nonPartnerPersonalPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        $and: [
          { province: { $exists: true } },
          { $or: [{ partnerAsPersonalPoa: 'no' }, { hasPartner: 'no' }] },
        ],
      },

      interceptModal: [
        {
          label: ({ answerStore: { province } }) => ({
            tKey: 'questionnaire.personalPoa:non-partner-personal-poa.interceptModal.item1.label',
            options: {
              term: provinceDictionary(ONTARIO_TERMS.ATTORNEY, province),
            },
          }),

          subLabel: {
            tKey: 'questionnaire.personalPoa:non-partner-personal-poa.interceptModal.item1.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.personalPoa:non-partner-personal-poa.interceptModal.item1.primaryButtonLabel',
          },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.TO_QUESTION,
            questionId: 1572444357,
          },
          secondaryButtonLabel: { tKey: 'common:close' },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },

          trigger: {
            $and: [
              {
                nonPartnerPersonalPoaRelationship:
                  CONSTANTS.relationshipValues.MY_PARTNER,
              },
              { partnerAsPersonalPoa: 'no' },
            ],
          },
        },
      ],
    },

    {
      id: 1572444848,
      pathname: 'non-partner-alt-personal-poa',
      section,
      optional: true,

      label: ({ answerStore: { nonPartnerPersonalPoaName } }) => ({
        tKey: 'questionnaire.personalPoa:non-partner-alt-personal-poa.label',
        options: { nonPartnerPersonalPoaName },
      }),

      subLabel: subLabelNoHealthcareWorkers,
      fields: [
        {
          name: 'altNonPartnerPersonalPoaName',
          type: 'text',
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            const ontarioTerm = ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE
            return {
              tKey: 'questionnaire.personalPoa:non-partner-alt-personal-poa.fields.altNonPartnerPersonalPoaName.reviewLabel',
              options: { term: provinceDictionary(ontarioTerm, province) },
            }
          },
          appointeeType: appointeeTypes.ALTERNATE_PERSONAL_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'altNonPartnerPersonalPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { nonPartnerPersonalPoaName: { $exists: true } },
          {
            $or: [{ partnerAsPersonalPoa: 'no' }, { hasPartner: 'no' }],
          },
        ],
      },
    },

    {
      id: 1575580026,
      pathname: 'dnr',
      section,
      label: { tKey: 'questionnaire.personalPoa:dnr.label' },
      label2: { tKey: 'questionnaire.personalPoa:dnr.label2' },

      sidebar: personalPoaDnrSidebar,

      trigger: { firstName: { $exists: true } },
      fields: [
        {
          name: 'dnr',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.personalPoa:dnr.fields.dnr.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: {
                tKey: 'questionnaire.personalPoa:dnr.fields.dnr.options.yes.label',
              },
            },
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.personalPoa:dnr.fields.dnr.options.no.label',
              },
            },
            {
              value: 'attorney',
              label: ({ answerStore }) => {
                const { province } = answerStore
                return {
                  tKey: 'questionnaire.personalPoa:dnr.fields.dnr.options.attorney.label',
                  options: {
                    term: provinceDictionary(ONTARIO_TERMS.ATTORNEY, province),
                  },
                }
              },
            },
            {
              value: 'unsure',
              label: {
                tKey: 'questionnaire.personalPoa:dnr.fields.dnr.options.unsure.label',
              },
            },
          ],
        },
      ],
    },

    {
      id: 1572444944,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.personalPoa:review.label' },
      subLabel: { tKey: 'questionnaire.personalPoa:review.subLabel' },
    },
  ],
}
