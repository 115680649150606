import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import dashboardSelectors from '../../../../scenes/Dashboard/ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../../scenes/Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import data from '../../../../scenes/Questionnaire/data'
import questionnaireSelectors from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { triggeredQuestions } from '../../../../scenes/Questionnaire/engine'
import commonSelectors from '../../../ducks/commonSelectors'
import filteredSectionData from '../utils/filteredSectionData'
import groupBySection from '../utils/groupBySection'
import SummaryCard from './SummaryCard'

const SummaryCards = ({
  abTest,
  referrer,
  payments,
  isPartner,
  documents,
  answerStore,
  accountCreated,
  charityPartner,
}) => {
  const theTriggeredQuestions = triggeredQuestions({
    abTest,
    referrer,
    payments,
    isPartner,
    documents,
    answerStore,
    theData: data,
    accountCreated,
    charityPartner,
  })
  const groupedBySection = groupBySection(data, theTriggeredQuestions)
  const filteredData = filteredSectionData(data, answerStore)

  return (
    <>
      {filteredData &&
        !_isEmpty(answerStore) &&
        filteredData.map((section) => (
          <SummaryCard
            key={section.id}
            label={section.id}
            section={groupedBySection[section.id]}
            answerStore={answerStore}
          />
        ))}
    </>
  )
}

SummaryCards.propTypes = {
  abTest: PropTypes.string.isRequired,
  isPartner: PropTypes.bool.isRequired,
  referrer: PropTypes.shape({}).isRequired,
  accountCreated: PropTypes.bool.isRequired,
  charityPartner: PropTypes.shape({}).isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  charityPartner: commonSelectors.getCharityPartner(state),
  payments: paymentsSelectors.getPayments(state.dashboard),
  isPartner: dashboardSelectors.getIsPartner(state.dashboard),
  documents: documentsSelectors.getDocuments(state.dashboard),
  abTest: questionnaireSelectors.getAbTest(state.questionnaire),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
})

export default connect(mapStateToProps)(SummaryCards)
