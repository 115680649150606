import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isNil from 'lodash/isNil'

import { Box, useMediaQuery } from '@mui/material'

import cn from 'classnames'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Text from '../../../../common/components/atoms/Text'
import HighlightPill from '../../../../common/components/molecules/HighlightPill'
import commonActions from '../../../../common/ducks/commonActions'
import commonSelectors from '../../../../common/ducks/commonSelectors'
import strToKebab from '../../../../common/formatting/strToKebab'
import navigationActions from '../../ducks/navigation/navigationActions'
import navigationSelectors from '../../ducks/navigation/navigationSelectors'
import questionnaireActions from '../../ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../ducks/questionnaire/questionnaireSelectors'
import validationActions from '../../ducks/validation/validationActions'
import validationSelectors from '../../ducks/validation/validationSelectors'
import { validate } from '../../utils/validation/validation'
import SectionProgress from '../atoms/SectionProgress'
import InterceptModal from '../molecules/InterceptModal'
import NavigationButtons from '../molecules/NavigationButtons'
import Label from './Label'
import QuestionGraphic from './QuestionGraphic'
import Sidebar from './Sidebar'
import StandardQuestion from './StandardQuestion'
import questionTypes from './utils/questionTypes'

const animateDistance = 222
const animations = {
  start: (isForward) => ({
    opacity: 0,
    x: isForward ? animateDistance : -animateDistance,
  }),
  enter: {
    x: 0,
    opacity: 1,
    transition: { bounce: 0, duration: 0.61, type: 'spring' },
  },
  leave: (isForward) => ({
    opacity: 0,
    transition: {
      x: { duration: 0.51 },
      opacity: { duration: 0.44 },
    },
    x: isForward ? -animateDistance : animateDistance,
  }),
}

const FormWrapper = styled.form`
  right: 50%;
  transform: translateX(50%);
  transition:
    opacity 0.4s,
    transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: transform, opacity;

  &.question-back-enter {
    transform: translateX(-33%);
    opacity: 0;
  }

  &.question-back-exit-active {
    transform: translateX(110%);
    opacity: 0;
  }

  &.question-next-enter {
    transform: translateX(140%);
    opacity: 0;
  }

  &.question-next-exit-active {
    transform: translateX(-2%);
    opacity: 0;
  }
`

const Question = ({
  question,
  referrer,
  answerCache,
  answerStore,
  navDirection,
  invalidFields,
  questionStack,
  charityPartner,
  accountCreated,
  sectionProgress,
  preventAnimation,
  displayValidation,
  questionFragments = {},
  sidebarProperties,
  interceptModalData = {},
  dispatchNextQuestion,
  dispatchUpdateSidebar,
  dispatchInterceptModal,
  dispatchPreventPrevious,
  dispatchUpdateValidation,
  dispatchDisplayValidation,
  dispatchSetQuestionFragments,
}) => {
  const {
    id,
    type,
    fields,
    section,
    sidebar,
    pathname,
    altProgressText,
    highlightPillText,
  } = question

  const HIDE_NAV_BUTTONS_QUESTION_TYPES = [
    'dropOff',
    'complete',
    'familyWill',
    'createAccount',
    'partnerDropOff',
    'inheritanceAge',
    'socialComplete',
    'accountCreated',
    'remoteDistribution',
    'socialCreateAccount',
    'unsupportedProvince',
    'corporateExecutorFeeAgreement',
    'corporateExecutorDisqualifiers',
  ]

  const [sidebarTransitionDuration, setSidebarTransitionDuration] =
    useState(undefined)
  const [isAnimating, setAnimating] = useState(false)

  const sidebarRef = useRef()
  const currentFieldHasUpdatedOnRender = useRef([])

  // This breakpoint is used to check if the sidebar is in mobile/tablet or
  // desktop view for animations and autoOpenSidebar. Make sure this value
  // is updated in all: Drawer.js, Question.js, and HelpWithThisQuestionSublabel.js
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('3xl'))

  const navigate = useNavigate()
  const location = useLocation()

  const sidebarPushStyles = {
    /* 
      When the sidebar is displayed and the user is on desktop, 
      push the question to the left by half the width of the
      sidebar. Keeps the question centered in the remaining
      portion of the screen
    */
    position: 'relative',
    right:
      sidebarProperties.isOpen && isDesktop
        ? `${_get(sidebarRef, 'current.children[0].offsetWidth', 371) / 2}px`
        : '0',
    transition: (theme) =>
      theme.transitions.create('right', {
        easing: theme.transitions.easing.easeOut,
        duration:
          sidebarTransitionDuration ||
          theme.transitions.duration.enteringScreen,
      }),
  }

  const handleFieldFragmentUpdate = (fragment) => {
    Object.keys(fragment).forEach((field) => {
      if (currentFieldHasUpdatedOnRender.current.includes(field)) return
      currentFieldHasUpdatedOnRender.current.push(field)
    })
    return dispatchSetQuestionFragments({ ...questionFragments, ...fragment })
  }

  useEffect(() => {
    const theInvalidFields = validate(question, questionFragments, answerCache)
    /*
     * Only update validation if prev invalid fields are different
     * than the current invalid fields
     */
    if (!_isEqual(invalidFields, theInvalidFields)) {
      dispatchUpdateValidation(theInvalidFields)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionFragments, question])

  useEffect(() => {
    /*
     * if question gets untriggered and then retriggered, ensure that all the question's
     * answerCache field-values make it to the answerStore even if input is unchanged
     */
    if (
      !_isEmpty(fields) &&
      fields.some(
        (field) =>
          // value for field exists in answerCache, but not in answerStore
          !_isNil(answerCache[field.name]) && _isNil(answerStore[field.name]),
      )
    ) {
      const theQuestionFragments = fields.reduce(
        (acc, field) =>
          currentFieldHasUpdatedOnRender.current.includes(field.name)
            ? acc
            : { ...acc, [field.name]: answerCache[field.name] },
        {},
      )

      if (!_isEmpty(theQuestionFragments)) {
        handleFieldFragmentUpdate(theQuestionFragments)
      }
    }

    if (section && pathname) {
      // update path when questionStack changes and convert all sections to kebab case
      navigate(
        `/questionnaire/${strToKebab(section)}/${pathname}${location.search}`,
      )
    }

    return () => {
      currentFieldHasUpdatedOnRender.current = []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionStack])

  useEffect(() => {
    if (isAnimating) return

    if (question.autoOpenSidebar && isDesktop) {
      setSidebarTransitionDuration(700)
      setTimeout(() => {
        dispatchUpdateSidebar({ isOpen: true })
        setSidebarTransitionDuration(undefined)
      }, 400)
    }

    if (question.autoCloseSidebar) {
      setSidebarTransitionDuration(380)
      setTimeout(() => {
        dispatchUpdateSidebar({ isOpen: false })
        setSidebarTransitionDuration(undefined)
      }, 400)
    }
  }, [question, isDesktop, isAnimating, dispatchUpdateSidebar])

  /*
   * If at any point the question + all fields of the question is valid and
   * the validation was displayed at one point, the validation can be removed
   */
  useEffect(() => {
    if (displayValidation && _isEmpty(invalidFields)) {
      dispatchDisplayValidation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidFields])

  const QuestionType = _get(questionTypes, type, StandardQuestion)

  const { t } = useTranslation()

  return (
    <Box sx={{ width: '100%' }}>
      <Sidebar
        referrer={referrer}
        data={sidebar}
        questionId={id}
        sidebarRef={sidebarRef}
        transitionDuration={sidebarTransitionDuration}
      />

      {/*
       * If there is no altProgressText, the progress bar
       * should not animate with the question, which is why the
       * SectionProgress component is here.
       */}
      {altProgressText === undefined && (
        <Box
          sx={{
            ...sidebarPushStyles,
            mt: {
              xxs: '2rem',
              md: '2.5rem',
              lg: '3rem',
              xl: '4rem',
            },
          }}
        >
          <SectionProgress value={sectionProgress} />
        </Box>
      )}

      {!_isEmpty(question) && !preventAnimation && (
        <Box
          sx={{
            ...sidebarPushStyles,
            width: '100%',
            position: 'absolute',
          }}
        >
          <FormWrapper
            className={cn(
              altProgressText === undefined
                ? 'pt-10 md:pt-14' // padding under progress bar
                : 'pt-10 md:pt-16 lg:pt-20', // no progress bar, padding under header
              'form-wrapper max-w-4xl absolute w-full flex flex-col items-center pb-32 px-4',
              '2xs:px-6',
              'sm:px-8 sm:pb-40',
              'md:px-16 md:pb-48',
            )}
            onSubmit={(e) => e.preventDefault()}
          >
            <AnimatePresence
              mode="wait"
              initial={false}
              custom={navDirection === 'forward'}
            >
              {/*
               * This inner wrapper allows for Intro question's Blob Progress to be
               * positioned absolutely at the top of the question.
               */}
              <motion.div
                className="relative w-full"
                key={id}
                custom={navDirection === 'forward'}
                variants={animations}
                initial="start"
                animate="enter"
                exit="leave"
                onAnimationStart={() => {
                  dispatchPreventPrevious(true)
                  setAnimating(true)
                }}
                onAnimationComplete={() => {
                  dispatchPreventPrevious(false)
                  setAnimating(false)
                }}
              >
                <Box sx={{ position: 'absolute', width: '100%', pb: '10rem' }}>
                  {/* Only used for intro questions */}
                  {highlightPillText && (
                    <div
                      className={cn(
                        /*
                         * Do not leave space for blob progress for POA intros
                         * because they do not have blob progress
                         */
                        { 'mt-24 sm:mt-28': !section.includes('POA') },
                        'm-auto mb-4 flex justify-center',
                      )}
                    >
                      <HighlightPill
                        variant="secondary"
                        label={
                          <Text
                            align="center"
                            display="inline"
                            sx={{ fontSize: '0.8125rem' }}
                          >
                            {t('common:introCompleteTime.part1')}
                            <Text
                              weight="bold"
                              display="inline"
                              sx={{ fontSize: '0.8125rem' }}
                            >
                              {/*
                               * If tKey exists on highlightPillText, translate it,
                               * otherwise, return highlightPillText as is
                               */}
                              {t(
                                _get(
                                  highlightPillText,
                                  'tKey',
                                  highlightPillText,
                                ),
                              ).toLowerCase()}
                            </Text>
                            {t('common:introCompleteTime.part2')}
                          </Text>
                        }
                      />
                    </div>
                  )}

                  <QuestionGraphic
                    question={question}
                    charityPartner={charityPartner}
                  />

                  <Label question={question} />

                  {/* QUESTION COMPONENT */}
                  {QuestionType && (
                    <div
                      data-testid="question-component-wrapper"
                      className="w-full flex flex-col items-center justify-center flex-wrap pt-4"
                    >
                      <QuestionType
                        question={question}
                        answerCache={answerCache}
                        answerStore={answerStore}
                        invalidFields={invalidFields}
                        displayValidation={displayValidation}
                        handleFieldFragmentUpdate={(fragment) =>
                          handleFieldFragmentUpdate(fragment)
                        }
                      />
                    </div>
                  )}

                  {/* NAV BUTTONS */}
                  {/* hide nav buttons if question is specified to hide */}
                  {!HIDE_NAV_BUTTONS_QUESTION_TYPES.includes(type) && (
                    <NavigationButtons
                      nextLabel={
                        type === 'intro' ||
                        type === 'info' ||
                        type === 'sectionReview' ||
                        !accountCreated
                          ? { tKey: 'common:continue' }
                          : { tKey: 'common:saveAndContinue' }
                      }
                      // hideNextButton when field is of type 'quickRadioField'
                      hideNextButton={
                        !_isEmpty(fields) &&
                        fields.some((f) => f.type === 'quickRadio')
                      }
                      handleButtonClick={() =>
                        dispatchNextQuestion({ questionFragments })
                      }
                    />
                  )}
                </Box>
              </motion.div>
            </AnimatePresence>
          </FormWrapper>
        </Box>
      )}

      {!_isEmpty(interceptModalData) && (
        <InterceptModal
          show={!_isEmpty(interceptModalData)}
          questionFragments={questionFragments}
          /*
           * Setting the interceptModal to an empty object updates
           * the value of interceptModal in the Redux store to {}.
           * The 'show' prop above will get the value of false and the
           * modal will close.
           */
          onClose={() => dispatchInterceptModal({})}
        />
      )}
    </Box>
  )
}

Question.propTypes = {
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    altProgressText: PropTypes.string,
    pathname: PropTypes.string,
    section: PropTypes.string,
    highlightPillText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    id: PropTypes.number,
    sidebar: PropTypes.arrayOf(PropTypes.shape({})),
    autoOpenSidebar: PropTypes.bool,
    autoCloseSidebar: PropTypes.bool,
  }),
  interceptModalData: PropTypes.shape({}),
  accountCreated: PropTypes.bool.isRequired,
  charityPartner: PropTypes.shape({}).isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  dispatchDisplayValidation: PropTypes.func.isRequired,
  dispatchInterceptModal: PropTypes.func.isRequired,
  dispatchSetQuestionFragments: PropTypes.func.isRequired,
  questionStack: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
  answerStore: PropTypes.shape({}).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  dispatchPreventPrevious: PropTypes.func.isRequired,
  dispatchUpdateValidation: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool.isRequired,
  questionFragments: PropTypes.shape({}),
  referrer: PropTypes.shape({}),
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  navDirection: PropTypes.oneOf(['forward', 'backward']).isRequired,
  sectionProgress: PropTypes.number,
  preventAnimation: PropTypes.bool.isRequired,
  dispatchUpdateSidebar: PropTypes.func.isRequired,
  sidebarProperties: PropTypes.shape({ isOpen: PropTypes.bool }).isRequired,
}

const mapStateToProps = (state) => ({
  questionStack: navigationSelectors.getQuestionStack(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  answerCache: questionnaireSelectors.getAnswerCache(state.questionnaire),
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
  displayValidation: validationSelectors.getDisplayValidation(
    state.questionnaire,
  ),
  charityPartner: commonSelectors.getCharityPartner(state),
  questionFragments: questionnaireSelectors.getQuestionFragments(
    state.questionnaire,
  ),
  navDirection: navigationSelectors.getNavDirection(state.questionnaire),
  sectionProgress: navigationSelectors.getSectionProgress(state.questionnaire),
  preventAnimation: navigationSelectors.getPreventAnimation(
    state.questionnaire,
  ),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  interceptModalData: navigationSelectors.getInterceptModal(
    state.questionnaire,
  ),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
  sidebarProperties: commonSelectors.getSidebar(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchDisplayValidation: (bool) =>
    dispatch(validationActions.displayValidation(bool)),
  dispatchNextQuestion: ({ questionFragments }) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
  dispatchPreventPrevious: (prevent) =>
    dispatch(navigationActions.preventPrevious(prevent)),
  dispatchUpdateValidation: (invalidFields) =>
    dispatch(validationActions.updateValidation({ invalidFields })),
  dispatchSetQuestionFragments: (fragments) =>
    dispatch(questionnaireActions.setQuestionFragments({ fragments })),
  dispatchInterceptModal: (interceptModalData) =>
    dispatch(navigationActions.interceptModal(interceptModalData)),
  dispatchUpdateSidebar: (sidebarProperties) =>
    dispatch(commonActions.updateSidebar(sidebarProperties)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Question)
