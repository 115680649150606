import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

// import { ClickAwayListener } from '@mui/material'

import _filter from 'lodash/filter'

import { CONSTANTS } from '@epilogue/common'

import questionnaireSelectors from '../../../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import HeaderContext from '../../context/HeaderContext'
import WorldMenuItem from './components/WorldMenuItem'

const WorldMenu = ({ questionnaireStarted }) => {
  const { availableWorlds, world: activeWorld } = useContext(HeaderContext)

  const worldsData = CONSTANTS.worldTypes

  const menuItems = _filter(
    worldsData,
    (worldData) =>
      worldData.type !== activeWorld &&
      availableWorlds.includes(worldData.type),
  )

  return (
    <div data-testid="world-menu" className="bg-white rounded-b-xl shadow-md">
      {menuItems.map(
        ({
          type,
          label,
          label2,
          dashboardPath,
          dashboardDescription,
          questionnaireDescription,
        }) => (
          <Fragment key={type}>
            <WorldMenuItem
              type={type}
              label={label}
              label2={label2}
              path={dashboardPath}
              dashboardDescription={dashboardDescription}
              questionnaireDescription={questionnaireDescription}
              started={questionnaireStarted({ worldType: type })}
            />
          </Fragment>
        ),
      )}
    </div>
  )
}

WorldMenu.propTypes = { questionnaireStarted: PropTypes.func.isRequired }

const mapStateToProps = (state) => ({
  questionnaireStarted: ({ worldType }) =>
    questionnaireSelectors.getQuestionnaireStarted(
      state.questionnaire,
      worldType,
    ),
})

export default connect(mapStateToProps)(WorldMenu)
