import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import LawyerReferral from '../../molecules/LawyerReferral'

const DropOff = ({ question, dispatchPreviousQuestion }) => {
  const { t } = useTranslation()
  const { dropOffLabel, dropOffType } = question

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        sx={{ position: 'absolute', top: '-3.1rem', left: '0' }}
        size="xxs"
        label={t('common:back')}
        startIcon={<ArrowBackIcon />}
        data-testid="upper-back-button"
        onClick={dispatchPreviousQuestion}
      />

      <Box sx={{ alignSelf: 'flex-start', mb: '1.4rem' }}>
        <LawyerReferral dropOffType={dropOffType} dropOffLabel={dropOffLabel} />
      </Box>

      <Box
        sx={{ display: 'flex', marginTop: '3rem', justifyContent: 'center' }}
      >
        <Button
          align="center"
          size="xxs"
          label={t('common:back')}
          startIcon={<ArrowBackIcon />}
          data-testid="lower-back-button"
          onClick={dispatchPreviousQuestion}
        />
      </Box>
    </Box>
  )
}

DropOff.propTypes = {
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  question: PropTypes.shape({
    dropOffLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]).isRequired,
    dropOffType: PropTypes.oneOf(Object.values(CONSTANTS.dropOffTypes))
      .isRequired,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropOff)
