import { CONSTANTS } from '@epilogue/common'

export default (data, answerStore) =>
  /*
   * We never want to display the 'complete' section on the summary page.
   * If the user's province is Quebec, we also do not want to display the POA sections.
   */
  data.filter(
    (s) =>
      s.id !== 'Complete' &&
      s.id !== 'Social Complete' &&
      (answerStore?.province !== CONSTANTS.provinces.QUEBEC ||
        (answerStore?.province === CONSTANTS.provinces.QUEBEC &&
          ![
            CONSTANTS.questionnaireSectionTypes.PROPERTY_POA.id,
            CONSTANTS.questionnaireSectionTypes.PERSONAL_POA.id,
          ].includes(s.id))),
  )
