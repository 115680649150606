import React, { useContext } from 'react'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../common/components/atoms/Heading'
import { themeColorTypes } from '../../../../../../../../common/styles/muiTheme'
import LawyerReferralContext from '../../../context/LawyerReferralContext'

const useStyles = makeStyles({ heading: { fontSize: '1.27rem' } })

const FlatFeeLawyerHeading = () => {
  const classes = useStyles()

  const {
    overrideLanguage,
    /*
     * For now, for flat fee referral pages, we only take the
     * first lawyer in the array
     */
    lawyers: [lawyer],
    answerStore,
  } = useContext(LawyerReferralContext)

  const { lawyerFirstName } = lawyer
  const { province } = answerStore

  const { t } = useTranslation()

  return (
    <Box marginBottom="3.7rem">
      <Heading
        variant="h3"
        align="center"
        className={classes.heading}
        color={themeColorTypes.ACCENT_1}
      >
        {t(
          'molecules:LawyerReferral.components.FlatFeeLawyerReferral.components.FlatFeeLawyerHeading.label',
          { lawyerFirstName, province, lng: overrideLanguage },
        )}
      </Heading>
    </Box>
  )
}

export default FlatFeeLawyerHeading
