// TS-TODO: type this file's export object

import { CONSTANTS } from '@epilogue/common'

import { supportedProvinces } from '../../../../../../../../../common/utils/provinces'
import fallbackLanguage from '../../../../../../../../Questionnaire/data/utils/fallbackLanguage'

const DOC_TYPE = CONSTANTS.docTypes.WISHES
const namespace = `${DOC_TYPE}_`

export default {
  [DOC_TYPE]: {
    id: { tKey: 'bonusDocs:wishes.id' },
    description: { tKey: 'bonusDocs:wishes.description' },
    subLabel: { tKey: 'bonusDocs:wishes.subLabel' },
    questions: [
      {
        id: 1667328193,
        pathname: 'province',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.province.label' },
        fields: [
          {
            name: 'province',
            type: 'quickRadio',
            options: supportedProvinces.map(({ value, tKey }) => ({
              value,
              label: { tKey },
            })),
          },
        ],
        trigger: { province: { $exists: false } },
      },
      {
        id: 1666729816,
        pathname: 'name',
        type: 'name',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.name.label' },
        fields: [
          {
            name: 'firstName',
            type: 'text',
            placeholder: {
              tKey: 'bonusDocs:wishes.name.fields.firstName.placeholder',
            },
          },
          {
            name: 'middleName',
            type: 'text',
            placeholder: {
              tKey: 'bonusDocs:wishes.name.fields.middleName.placeholder',
            },
            optional: true,
          },
          {
            name: 'lastName',
            type: 'text',
            placeholder: {
              tKey: 'bonusDocs:wishes.name.fields.lastName.placeholder',
            },
          },
        ],
        trigger: {
          $or: [
            { firstName: { $exists: false } },
            { lastName: { $exists: false } },
          ],
        },
      },
      {
        id: 1580910583,
        pathname: 'body',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.body.label' },
        fields: [
          {
            name: `${namespace}body`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: 'buried',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.buried',
                },
              },
              {
                value: 'mausoleum',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.mausoleum',
                },
              },
              {
                value: 'cremated',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.cremated',
                },
              },
              {
                value: 'green',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.green',
                },
              },
              {
                value: 'donated',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.donated',
                },
              },
              {
                value: 'family',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.family',
                },
              },
              {
                value: 'unsure',
                label: {
                  tKey: 'bonusDocs:wishes.body.fields.body.options.unsure',
                },
              },
            ],
          },
        ],
      },
      {
        id: 1580910588,
        pathname: 'ashes',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.ashes.label' },
        fields: [
          {
            name: `${namespace}ashes`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: 'scatteredLand',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.scatteredLand',
                },
              },
              {
                value: 'scatteredWater',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.scatteredWater',
                },
              },
              {
                value: 'urn',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.urn',
                },
              },
              {
                value: 'buried',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.buried',
                },
              },
              {
                value: 'family',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.family',
                },
              },
              {
                value: 'unsure',
                label: {
                  tKey: 'bonusDocs:wishes.ashes.fields.ashes.options.unsure',
                },
              },
            ],
          },
        ],
        trigger: { [`${namespace}body`]: 'cremated' },
      },
      {
        id: 1580910592,
        pathname: 'service',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.service.label' },
        fields: [
          {
            name: `${namespace}ceremony`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: 'funeral',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.funeral',
                },
              },
              {
                value: 'memorial',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.memorial',
                },
              },
              {
                value: 'celebration',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.celebration',
                },
              },
              {
                value: 'noService',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.noService',
                },
              },
              {
                value: 'family',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.family',
                },
              },
              {
                value: 'unsure',
                label: {
                  tKey: 'bonusDocs:wishes.service.fields.ceremony.options.unsure',
                },
              },
            ],
          },
        ],
      },
      {
        id: 1683657896,
        pathname: 'charitable-donation',
        section: DOC_TYPE,
        label: ({ charityPartner }: { charityPartner: any }) => {
          const { displayName } = charityPartner
          return {
            tKey: 'bonusDocs:wishes.charitable-donation.label',
            options: {
              charityPartnerDisplayName: fallbackLanguage(displayName),
            },
          }
        },
        fields: [
          {
            name: `${namespace}charitableDonation`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: ({ charityPartner }: { charityPartner: any }) =>
                  `${charityPartner.name}${
                    charityPartner.number
                      ? ` (Charity Number #${charityPartner.number})`
                      : ''
                  }`,
                label: {
                  tKey: 'bonusDocs:wishes.charitable-donation.fields.charitableDonation.options.yes',
                },
              },
              {
                value: 'no',
                label: {
                  tKey: 'bonusDocs:wishes.charitable-donation.fields.charitableDonation.options.no',
                },
              },
            ],
          },
        ],
        trigger: { 'charityPartner.name': { $exists: true } },
      },
      {
        id: 1580910579,
        pathname: 'organs',
        section: DOC_TYPE,
        label: [
          { tKey: 'bonusDocs:wishes.organs.label.item1' },
          {
            term: { tKey: 'bonusDocs:wishes.organs.label.item2.term' },
            definition: {
              tKey: 'bonusDocs:wishes.organs.label.item2.definition',
            },
          },
          { tKey: 'bonusDocs:wishes.organs.label.item3' },
        ],
        fields: [
          {
            name: `${namespace}organDonation`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: 'transplant',
                label: {
                  tKey: 'bonusDocs:wishes.organs.fields.organDonation.options.transplant',
                },
              },
              {
                value: 'transplantResearch',
                label: {
                  tKey: 'bonusDocs:wishes.organs.fields.organDonation.options.transplantResearch',
                },
              },
              {
                value: 'noDonation',
                label: {
                  tKey: 'bonusDocs:wishes.organs.fields.organDonation.options.noDonation',
                },
              },
              {
                value: 'family',
                label: {
                  tKey: 'bonusDocs:wishes.organs.fields.organDonation.options.family',
                },
              },
              {
                value: 'unsure',
                label: {
                  tKey: 'bonusDocs:wishes.organs.fields.organDonation.options.unsure',
                },
              },
            ],
          },
        ],
      },
      {
        id: 1694721410,
        pathname: 'tissue',
        section: DOC_TYPE,
        label: [
          { tKey: 'bonusDocs:wishes.tissue.label.item1' },
          {
            term: { tKey: 'bonusDocs:wishes.tissue.label.item2.term' },
            definition: {
              tKey: 'bonusDocs:wishes.tissue.label.item2.definition',
            },
          },
          { tKey: 'bonusDocs:wishes.tissue.label.item3' },
        ],
        fields: [
          {
            name: `${namespace}tissueDonation`,
            optional: true,
            type: 'quickRadio',
            options: [
              {
                value: 'transplant',
                label: {
                  tKey: 'bonusDocs:wishes.tissue.fields.tissueDonation.options.transplant',
                },
              },
              {
                value: 'transplantResearch',
                label: {
                  tKey: 'bonusDocs:wishes.tissue.fields.tissueDonation.options.transplantResearch',
                },
              },
              {
                value: 'noDonation',
                label: {
                  tKey: 'bonusDocs:wishes.tissue.fields.tissueDonation.options.noDonation',
                },
              },
              {
                value: 'family',
                label: {
                  tKey: 'bonusDocs:wishes.tissue.fields.tissueDonation.options.family',
                },
              },
              {
                value: 'unsure',
                label: {
                  tKey: 'bonusDocs:wishes.tissue.fields.tissueDonation.options.unsure',
                },
              },
            ],
          },
        ],
      },
      {
        id: 1580910596,
        pathname: 'additional-wishes',
        section: DOC_TYPE,
        label: { tKey: 'bonusDocs:wishes.additional-wishes.label' },
        subLabel: { tKey: 'bonusDocs:wishes.additional-wishes.subLabel' },
        fields: [
          {
            name: `${namespace}additionalWishes`,
            optional: true,
            type: 'textArea',
            placeholder: {
              tKey: 'bonusDocs:wishes.additional-wishes.fields.additionalWishes.placeholder',
            },
            placeholder2: {
              tKey: 'bonusDocs:wishes.additional-wishes.fields.additionalWishes.placeholder2',
            },
            maxLength: 500,
            characterCount: true,
            rows: '5',
          },
        ],
      },
    ],
  },
}
