import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Investissement en ligne à faible coût et à faible effort

Investissez dans votre avenir avec RBC Investi-Clic — il combine la gestion professionnelle de portefeuille avec une expérience numérique pratique vous permettant d'investir sans effort ni frais élevés. Notre équipe dédiée de conseillers en portefeuille choisira, achètera et gérera vos investissements pour vous afin que vous puissiez consacrer votre temps à d'autres choses.

#### Voici à quel point il est facile de commencer :

1. Répondez à quelques questions simples en ligne pour que nous puissions mieux vous connaître.
2. Nous vous orienterons vers un portefeuille diversifié de fonds négociés en bourse (FNB) conçu pour correspondre à vos objectifs d'investissement et à votre tolérance au risque.
3. Ouvrez un compte de placement CELI, REER, CELIAPP ou non enregistré et effectuez votre premier dépôt — nous nous occuperons du reste !
<br>

---

<br>
<div style="display:flex;">
  <div style="display:block; line-height: 1.1;"><sup><sub>1</sub></sup></div>
  <span style="display:block; line-height: 1.1;">
    <div style="display:block; margin-left:15px;"><sup><sub>To take advantage of this offer you must not have held an RBC InvestEase account prior to September 1, 2024. This offer applies to your first RBC InvestEase account only. Accounts opened from September 1, 2024 to February 28, 2025 using promo code SA509 will not be charged the regular 0.5% management fee by RBC InvestEase for 6 months from the date of account opening. RBC InvestEase will notify clients 60 days in advance of any changes to the fees associated with their account as set out in the investment management agreement. A weighted average management expense ratio will still apply to the ETFs held in our portfolios. For further information please refer to Pricing on https://www.rbcinvestease.com . This offer cannot be combined with any other offers. RBC InvestEase Inc. reserves the right to amend or withdraw this offer at any time without notice.</sub></sup></div>
    
    <div style="display:block; margin-top:20px;"><sup><sub>Epilogue and RBC InvestEase (RBC IE) have agreed to engage in a reciprocal marketing campaign. Under this arrangement, Epilogue may benefit from the promotion of its products and services by RBC IE for referring you to RBC IE. RBC IE is registered as a Restricted Portfolio Manager with the securities regulatory authorities in all provinces and territories in Canada, and provides discretionary investment management services. Epilogue provides online Wills and Power of Attorney services. Epilogue is not permitted to perform any activity requiring a securities registration under Canadian securities laws. All activities requiring a securities registration resulting from the referral arrangement will be performed by RBC IE. The possibility of a conflict of interest may arise under any referral arrangement. Epilogue may have an interest in referring you to RBC IE where RBC IE in turn is referring prospective clients to Epilogue.</sub></sup></div>
  </span>
</div>
`
    : `
#### Low-Cost, Low-Effort, Online Investing

Invest in your future with RBC InvestEase—it combines professional portfolio management with a convenient digital experience
allowing you to invest without the work or high fees. Our dedicated team of portfolio advisors will pick, buy and manage your investments for you, so you can spend your time on other things.

#### Here's how easy it is to get started:

1. Answer some simple online questions so we can get to know you.
2. We'll align you to a diversified portfolio of exchange-traded funds (ETFs) designed to meet with your investing goals and feelings about risk.
3. Open a TFSA, RRSP, FHSA or non-registered investment account and make your first deposit—we'll take care of the rest!
<br>

---

<br>
<div style="display:flex;">
  <div style="display:block; line-height: 1.1;"><sup><sub>1</sub></sup></div>
  <span style="display:block; line-height: 1.1;">
    <div style="display:block; margin-left:15px;"><sup><sub>To take advantage of this offer you must not have held an RBC InvestEase account prior to September 1, 2024. This offer applies to your first RBC InvestEase account only. Accounts opened from September 1, 2024 to February 28, 2025 using promo code SA509 will not be charged the regular 0.5% management fee by RBC InvestEase for 6 months from the date of account opening. RBC InvestEase will notify clients 60 days in advance of any changes to the fees associated with their account as set out in the investment management agreement. A weighted average management expense ratio will still apply to the ETFs held in our portfolios. For further information please refer to Pricing on https://www.rbcinvestease.com . This offer cannot be combined with any other offers. RBC InvestEase Inc. reserves the right to amend or withdraw this offer at any time without notice.</sub></sup></div>
    
    <div style="display:block; margin-top:20px;"><sup><sub>Epilogue and RBC InvestEase (RBC IE) have agreed to engage in a reciprocal marketing campaign. Under this arrangement, Epilogue may benefit from the promotion of its products and services by RBC IE for referring you to RBC IE. RBC IE is registered as a Restricted Portfolio Manager with the securities regulatory authorities in all provinces and territories in Canada, and provides discretionary investment management services. Epilogue provides online Wills and Power of Attorney services. Epilogue is not permitted to perform any activity requiring a securities registration under Canadian securities laws. All activities requiring a securities registration resulting from the referral arrangement will be performed by RBC IE. The possibility of a conflict of interest may arise under any referral arrangement. Epilogue may have an interest in referring you to RBC IE where RBC IE in turn is referring prospective clients to Epilogue.</sub></sup></div>
  </span>
</div>
`
