import React from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as WarningIcon } from '../../../../../../assets/images/warning-icon.svg'
import Button from '../../../../../atoms/Button'
import Heading from '../../../../../atoms/Heading'
import Modal from '../../../../../atoms/Modal'

interface Props {
  show: boolean
  province: string
  onClose: () => void
  onConfirm: () => void
}

const LanguageChangeInterceptModal = ({
  province,
  show,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="sm"
      onClose={onClose}
      data-testid="language-change-intercept-modal"
    >
      <Box>
        <Box sx={{ mx: 'auto', py: '2.1rem' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <WarningIcon />

            <Box sx={{ my: '1.15rem', textAlign: 'center' }}>
              <Heading variant="h4">
                {t('components:LanguageChangeInterceptModal.label', {
                  province,
                })}
              </Heading>
            </Box>
          </Box>

          <Box
            sx={{
              mt: '1.6rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Button
              variant="contained"
              label={t('common:continue')}
              onClick={() => {
                onConfirm()
                onClose()
              }}
              data-testid="confirm-language-change"
            />
            <Button
              size="xs"
              label={t('common:cancel')}
              onClick={() => {
                onClose()
              }}
              sx={{ mt: { min: '1rem', sm: '0.6rem' } }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default LanguageChangeInterceptModal
