import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Heading from '../../../../../common/components/atoms/Heading'
import commonActions from '../../../../../common/ducks/commonActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import FlatFeeLawyerReferral from './components/FlatFeeLawyerReferral'
import StandardLawyerReferral from './components/StandardLawyerReferral'
import LawyerReferralContext from './context/LawyerReferralContext'
import useLawyers from './hooks/useLawyers'

const flatFeeProvinces = new Set([
  CONSTANTS.provinces.ONTARIO,
  CONSTANTS.provinces.MANITOBA,
])

const LawyerReferral = ({
  referrer,
  dropOffType,
  answerStore,
  dropOffLabel,
  dispatchSetGlobalErrorMessage,
}) => {
  const { t } = useTranslation()

  const [lawyerReferralData, setLawyerReferralData] = useState({
    name: '',
    email: '',
    phone: '',
    referredLawyerIds: [],
  })

  const { province } = answerStore
  const isFlatFee = flatFeeProvinces.has(province)

  const { data: lawyers } = useLawyers({
    referrer,
    province,
    dropOffType,
    flatFee: isFlatFee,
    dispatchSetGlobalErrorMessage,
  })

  /*
   * If the user's language is English, set to undefined because we don't
   * need to override the language since it is assumed that all of our lawyers
   * speak English by default.
   *
   * If the user's language is not English, check to see if all the
   * lawyers in the array speak the user's language. If all the lawyers do, speak
   * the user's selected language, we set to undefined and let the translations
   * work as expected. If all of the lawyers do not speak the user's language, we
   * need to override with English so that we don't have some lawyers displayed in
   * one language and some in another.
   */
  const overrideLanguage =
    i18n.language === CONSTANTS.languageTypes.EN_CA.id ||
    (!_isEmpty(lawyers) && lawyers.every(({ bio }) => bio[i18n.language]))
      ? undefined
      : CONSTANTS.languageTypes.EN_CA.id

  const contextData = useMemo(
    () => ({
      lawyers,
      answerStore,
      overrideLanguage,
      lawyerReferralData,
      setLawyerReferralData,
      dispatchSetGlobalErrorMessage,
    }),
    [
      lawyers,
      answerStore,
      overrideLanguage,
      lawyerReferralData,
      dispatchSetGlobalErrorMessage,
    ],
  )

  return (
    <>
      <Heading variant="h3" align="center" sx={{ mb: '2rem' }}>
        {/* If dropOffLabel has tKey, translate it, otherwise return as is */}
        {t(_get(dropOffLabel, 'tKey', dropOffLabel), { lng: overrideLanguage })}
      </Heading>

      <LawyerReferralContext.Provider value={contextData}>
        {isFlatFee ? <FlatFeeLawyerReferral /> : <StandardLawyerReferral />}
      </LawyerReferralContext.Provider>
    </>
  )
}

LawyerReferral.propTypes = {
  dropOffLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]).isRequired,
  dropOffType: PropTypes.oneOf(Object.values(CONSTANTS.dropOffTypes))
    .isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
  referrer: PropTypes.shape({}).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
})

export default connect(mapStateToProps, mapDispatchToProps)(LawyerReferral)
