// TAG: quebec
import React from 'react'

import { connect } from 'react-redux'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import commonActions from '../../../../../common/ducks/commonActions'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import navigationActions from '../../../ducks/navigation/navigationActions'

interface Props {
  question: any
  dispatchNextQuestion: any
  dispatchUpdateSidebar: ({ isOpen }: { isOpen: boolean }) => void
}

const ProductQuebec = ({
  question,
  dispatchUpdateSidebar,
  dispatchNextQuestion,
}: Props) => {
  const { t } = useTranslation()
  const [field] = question.fields

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Button
        color={themeColorTypes.ACCENT_1}
        sx={{ mb: '2.2rem', mt: '-0.4rem' }}
        colorVariant={themeColorVariants.DARKER}
        label={t('customQuestion:ProductQuebec.subLabel')}
        onClick={() => dispatchUpdateSidebar({ isOpen: true })}
      />

      <Button
        size="lg"
        type="submit"
        variant="contained"
        iconFontSize="1.15rem"
        endIcon={<ArrowForwardIcon />}
        data-testid="primary-navigation-button"
        label={t(field.options[0].label.tKey)}
        onClick={() => {
          dispatchNextQuestion({
            questionFragments: { [field.name]: field.options[0].value },
          })
        }}
      />
      <Box sx={{ mt: '0.9rem' }}>
        <Button
          size="xs"
          iconFontSize="1rem"
          endIcon={<ArrowForwardIcon />}
          label={t(field.options[1].label.tKey)}
          color={themeColorTypes.GREY}
          colorVariant={themeColorVariants.DARK}
          data-testid="secondary-navigation-button"
          onClick={() => {
            dispatchNextQuestion({
              questionFragments: { [field.name]: field.options[1].value },
            })
          }}
        />
      </Box>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatchNextQuestion: ({ questionFragments }: Record<string, string>) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
  dispatchUpdateSidebar: (sidebarProperties: { isOpen: boolean }) =>
    dispatch(commonActions.updateSidebar(sidebarProperties)),
})

export default connect(null, mapDispatchToProps)(ProductQuebec)
