import { CONSTANTS } from '@epilogue/common'

import fallbackLanguage from '../utils/fallbackLanguage'

export default [
  {
    label: { tKey: 'interceptModals:allToPartnerInterceptModals.item1.label' },

    subLabel: ({
      answerStore: { partnerFirstName },
      charityPartner: { displayName: charityPartnerDisplayName },
    }) => ({
      tKey: 'interceptModals:allToPartnerInterceptModals.item1.subLabel',
      options: {
        partnerFirstName,
        charityPartnerDisplayName: fallbackLanguage(charityPartnerDisplayName),
      },
    }),

    primaryButtonLabel: ({ answerStore: { partnerFirstName } }) => ({
      tKey: 'interceptModals:allToPartnerInterceptModals.item1.primaryButtonLabel',
      options: { partnerFirstName },
    }),

    primaryButtonAction: {
      type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
    },

    secondaryButtonIcon: true,

    secondaryButtonLabel: ({
      charityPartner: { displayName: charityPartnerDisplayName },
    }) => ({
      tKey: 'interceptModals:allToPartnerInterceptModals.item1.secondaryButtonLabel',
      options: {
        charityPartnerDisplayName: fallbackLanguage(charityPartnerDisplayName),
      },
    }),

    secondaryButtonAction: {
      type: CONSTANTS.interceptActionTypes.TO_QUESTION,
      questionId: ({ answerStore: { charityPartnerMirrorFamilyGift } }) => {
        const charityPartnerGiftTypeQuestionId = 1642619114
        const charityPartnerMirrorFamilyGiftQuestionId = 1644267723
        /*
         * When a user is mirroring a Family Legacy gift, in this case, we want
         * to bring them to that 'charityPartnerMirrorFamilyGift' question because
         * they will not have a triggered 'charityPartnerGiftType' question. If they don't
         * have a charityPartnerMirrorFamilyGift question, we can assume that they do have
         * a 'charityPartnerGiftType' question, and we can safely bring the user there
         */
        return charityPartnerMirrorFamilyGift === 'yes'
          ? charityPartnerMirrorFamilyGiftQuestionId
          : charityPartnerGiftTypeQuestionId
      },
    },

    trigger: {
      allToPartner: 'no',
      $or: [
        {
          charityPartnerMirrorFamilyGift: 'yes',
          'charityPartnerGiftDetails.isPercent': true,
        },
        {
          charityPartnerGiftType: '%',
          charityPartnerGiftTiming: 'familyLegacy',
          charityPartnerMirrorFamilyGift: { $ne: 'yes' },
        },
      ],
    },
  },

  // Intercept modal T
  {
    label: ({
      answerStore: { partnerFirstName },
      charityPartner: { displayName },
    }) => ({
      tKey: 'interceptModals:allToPartnerInterceptModals.item2.label',
      options: {
        partnerFirstName,
        charityPartnerDisplayName: fallbackLanguage(displayName),
      },
    }),

    primaryButtonLabel: { tKey: 'common:close' },
    primaryButtonAction: {
      type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
    },

    trigger: {
      allToPartner: 'yes',
      charityPartnerGiftType: '%',
      charityPartnerGiftTiming: 'personal',
      charityPartnerGiftAmount: { $exists: true },
      $where() {
        const charityPartnerPercentageGift = parseInt(
          this.charityPartnerGiftAmount,
        )
        return charityPartnerPercentageGift === 100
      },
    },
  },
]
