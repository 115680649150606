import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import LanguageIcon from '@mui/icons-material/Language'

import { CONSTANTS } from '@epilogue/common'

import i18n from '../../../../../../i18n'
import questionnaireSelectors from '../../../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import Button from '../../../../atoms/Button'
import LanguageChangeInterceptModal from './components/LanguageChangeInterceptModal'

interface Props {
  answerStore: any
}

type LanguageType =
  (typeof CONSTANTS.languageTypes)[keyof typeof CONSTANTS.languageTypes]['id']

const LanguageSwitcher = ({ answerStore }: Props) => {
  const [language, setLanguage] = useState<LanguageType>(
    i18n.language as LanguageType,
  )
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const { province } = answerStore

  const nextLanguage =
    language === CONSTANTS.languageTypes.EN_CA.id
      ? CONSTANTS.languageTypes.FR_CA
      : CONSTANTS.languageTypes.EN_CA

  return (
    <>
      <Button
        startIcon={<LanguageIcon sx={{ mr: '-0.2rem' }} />}
        data-testid="language-button"
        label={nextLanguage.shortLabel}
        onClick={() => {
          // If the user is not in Quebec and is switching away from English, show modal
          if (
            province !== CONSTANTS.provinces.QUEBEC &&
            nextLanguage !== CONSTANTS.languageTypes.EN_CA
          ) {
            setShowModal(true)
          } else {
            setLanguage(nextLanguage.id)
          }
        }}
      />
      <LanguageChangeInterceptModal
        show={showModal}
        province={province}
        onClose={() => setShowModal(false)}
        onConfirm={() => setLanguage(nextLanguage.id)}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(LanguageSwitcher)
